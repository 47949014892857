
import axios from "axios";
import { API_BASE_URL, ACCESS_TOKEN_NAME } from "@/config/serverApiConfig";
import variables from "@/config/variables";
import localOrSessionStore from "@/utils/localOrSessionStore";

const headersInstance = {
    "Content-Type": "application/json", };

export const axiosInstance = axios.create({
    baseURL: API_BASE_URL + "/v1",
    timeout: 30000,

});

const storage = localOrSessionStore({ key: variables.REMEMBER });

axiosInstance.interceptors.request.use(
    (config) => {
        const token = storage.getItem(variables.TOKEN);
        if (!config.headers.Authorization) {

            if (token) {
                config.headers = {
                    Authorization: `bearer ${token}`,
                    "Content-Type": "application/json",
                };


            }
        }
        return config;
    },
    (error) => {

        // store.dispatch(setAPIErrorMessage(error.message));
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        const refreshToken = storage.getItem(variables.TOKENREFRESH);
        const user = JSON.parse(storage.getItem(variables.USER));

        if (refreshToken && user) {
            if ( (error.response.status === 498  ) && !originalRequest._retry) {
                originalRequest._retry = true;

                return axios
                    .post( `${API_BASE_URL}/v1/auth/refresh`, {
                        refreshToken: refreshToken,
                        username: user.email,
                    })
                    .then(({data}) => {

                        storage.setItem(variables.TOKEN, data.result.token)
                        storage.setItem(variables.TOKENREFRESH, data.result.refreshToken)

                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + data.result.token;
                        originalRequest.headers["Authorization"] =
                            "Bearer " + data.result.token;
                        return axiosInstance(originalRequest);
                    });
            }
        }

        return Promise.reject(error);
    }
);
