
import actionTypes from "@/config/actionTypes";


export const badgeActions = {
    getUserBadges,saveUserBadge,updateBadges,clearupdateBadges
};

function updateBadges(data) {
    console.log("Actualizamos")
    return {
        type: actionTypes.UPDATE_USERBADGES,
        payload: {data}
    };
}
function clearupdateBadges() {
    return {
        type: actionTypes.CLEAR_UPDATE_USERBADGES
    };
}
function getUserBadges() {
    return {
        type: actionTypes.GET_USERBADGES
    };
}


function saveUserBadge(payload) {
    
    return {
        type: actionTypes.SAVE_USERBADGES,
        payload: payload
    };
}
