import history from "@/utils/history";
import {takeEvery, call, put, select} from 'redux-saga/effects'
import actionTypes from "@/config/actionTypes";
import {request} from "@/request";

export default function* watcherDegrees() {
    yield takeEvery(actionTypes.GET_DEGREES, getDegrees)

}


function* getDegrees({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    try {
        let apiEndpoint = `/user/degrees`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_DEGREES_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_DEGREES_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_DEGREES_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}

