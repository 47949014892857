import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";
import Login from "@/pages/auth/Login";

const Dashboard = lazy(() =>
    import(/*webpackChunkName:'DashboardPage'*/ "@/pages/Dashboard")
);
const IndexTutor = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/Index")
);

const DaysTutor = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/Days")
);
const StudentStateView = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/StudentStateView")
);


const StudentCompetenceView = lazy(() =>
    import(/*webpackChunkName:'AdminPage'*/ "@/pages/tutor/StudentCompetenceView")
);
const Logout = lazy(() =>
    import(/*webpackChunkName:'LogoutPage'*/ "@/pages/auth/Logout")
);
const NotFound = lazy(() =>
    import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);

export default function TutorRouter() {
    const location = useLocation();
    return (
        <Suspense fallback={<PageLoader />}>
            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                    <PrivateRoute path="/" component={IndexTutor} exact />
                    <PrivateRoute path="/days" component={DaysTutor} exact />
                    <PrivateRoute component={Logout} path="/logout" exact />
                    <PrivateRoute path="/student/:uuid/competences" component={StudentCompetenceView} exact />
                    <PrivateRoute path="/student/:uuid/state" component={StudentStateView} exact />

                    <Route
                        path="*"
                        component={NotFound}
                        render={() => <Redirect to="/notfound" />}
                    />
                </Switch>
            </AnimatePresence>
        </Suspense>
    );
}
