
export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL
export const FE_BASE_URL = process.env.REACT_APP_FRONT_URL

// export const API_BASE_URL = "https://wild-puce-reindeer-sari.cyclic.app/api/";
export const ACCESS_TOKEN_NAME = "AuthTECFE"
export const AUTH = "tec_interviewPortalAuth";
export const USER= "tec_interviewPortalUser";
export const TOKEN=  "tec_interviewPortalToken";
export const TOKENREFRESH=  "tec_interviewPortalRefreshToken";
export const REMEMBER = "tec_interviewPortalRemember";
export const USER_TYPE = "tec_interviewPortalUserType";
