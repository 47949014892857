import actionTypes from "@/config/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_USERSTEPS_DATA:
            return {
                ...state,
                userStepsError: null,
                userStepsData: action.payload,
            };
        case actionTypes.GET_USERSTEPS_ERROR:
            return {
                ...state,
                userStepsData: null,
                userStepsError: action.payload,
            };
        case actionTypes.SAVE_STEPUSER_DATA:
            return {
                ...state,
                saveStepUserError: null,
                saveStepUserData: action.payload,
            };
        case actionTypes.SAVE_STEPUSER_ERROR:
            return {
                ...state,
                saveStepUserData: null,
                saveStepUserError: action.payload,
            };
        case actionTypes.SAVE_STEPUSER_CLEAR:
            return {
                ...state,
                saveStepUserData: null,
                saveStepUserError: null,
                userStepsData: null,
                userStepsError: null,
            };

        default:
            return state;
    }

}
