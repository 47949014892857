import actionTypes from "@/config/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USERBADGES:
            return {
                ...state,
                updateUserBadges: action.payload,
            };
        case actionTypes.CLEAR_UPDATE_USERBADGES:
            return {
                ...state,
                updateUserBadges:null,
            };
        case actionTypes.GET_USERBADGES_DATA:
            return {
                ...state,
                userBadgesError: null,
                userBadgesData: action.payload,
            };
        case actionTypes.GET_USERBADGES_ERROR:
            return {
                ...state,
                userBadgesData: null,
                userBadgesError: action.payload,
            };


        case actionTypes.SAVE_USERBADGES_DATA:
            return {
                ...state,
                saveUserBadgesError: null,
                saveUserBadgesData: action.payload,
            };
        case actionTypes.SAVE_USERBADGES_ERROR:
            return {
                ...state,
                saveUserBadgesData: null,
                saveUserBadgesError: action.payload,
            };
        case actionTypes.SAVE_USERBADGES_CLEAR:
            return {
                ...state,
                saveUserBadgesData: null,
                saveUserBadgesError: null,
            };

        default:
            return state;
    }

}
