import { combineReducers } from "redux";
import loading from "./loading";
import authReducer from "./auth.reducer";
import stepReducer from "./step.reducer";
import degreeReducer from "./degree.reducer";
import questionnaireReducer from "./questionnaire.reducer";
import challengeReducer from "./challenge.reducer";
import userBadgesReducer from './badge.reducer'
const rootReducer = combineReducers({
    loading,authReducer,stepReducer,degreeReducer,questionnaireReducer,challengeReducer,userBadgesReducer
});

export default rootReducer;
