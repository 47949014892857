import actionTypes from "@/config/actionTypes";


export const questionnaireActions = {
     getQuestionnaire,getAssessmentQuestionnaire, saveAnswer,getAnswer, getReport
};


function getReport(code) {
    return {
        type: actionTypes.GET_USERREPORT,
        payload: {code}
    };
}


function getQuestionnaire(code) {
    return {
        type: actionTypes.GET_QUESTIONNAIRE_DEGREE,
        payload: {code}
    };
}


function getAssessmentQuestionnaire(uuid) {
    return {
        type: actionTypes.GET_ASSESSMENTQUESTIONNAIRE_DEGREE,
        payload: {uuid}
    };
}


function saveAnswer(id, answer) {
    return {
        type: actionTypes.SAVE_useranswers,
        payload: {id, answer}
    };
}


function getAnswer(id) {
    return {
        type: actionTypes.GET_useranswers,
        payload: {id}
    };
}

