import actionTypes from "@/config/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {

        
        case actionTypes.CLEAN_EMAIL_VERIFICATION:
            return {
                ...state,
                emailValid: undefined,
                emailError: undefined,
            };

        case actionTypes.LOGIN_DATA:
            return {
                ...state,
                error: null,
                user: action.payload,
            };
        case actionTypes.LOGIN_ERROR:
            return {
                ...state,
                user: null,
                error: action.payload,
            };
        case actionTypes.FORGOTPASSWORD_DATA:
            return {
                ...state,
                forgot: action.payload,
            };
        case actionTypes.FORGOTPASSWORD_ERROR:
            return {
                ...state,
                forgotError: action.payload,
            };
        case actionTypes.RESETPASSWORD_DATA:
            return {
                ...state,
                reset: action.payload,
            };
        case actionTypes.RESETPASSWORD_ERROR:
            return {
                ...state,
                resetError: action.payload,
            };

        case actionTypes.GET_USER_DATA:
            return {
                ...state,
                userDataProfile: action.payload,
            };
        case actionTypes.GET_USER_ERROR:
            return {
                ...state,
                userDataError: action.payload,
            };

        default:
            return state;
    }
};
