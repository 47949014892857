import actionTypes from "@/config/actionTypes";


export const stepActions = {
    getUserSteps,saveStepUser
};

function saveStepUser (step) {
    return {
        type: actionTypes.SAVE_STEPUSER,
        payload: {step}
    };
}

function getUserSteps() {
    return {
        type: actionTypes.GET_USERSTEPS
    };
}
