import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import PublicRoute from "./PublicRoute";
import PageLoader from "@/components/PageLoader";

import { API_BASE_URL, ACCESS_TOKEN_NAME } from "@/config/serverApiConfig";

const Login = lazy(() =>
  import(/*webpackChunkName:'LoginPage'*/ "@/pages/auth/Login")
);

const LoginF = lazy(() =>
  import(/*webpackChunkName:'LoginPage'*/ "@/pages/auth/LoginF")
);

const LoginSSO = lazy(() =>
    import(/*webpackChunkName:'LoginPage'*/ "@/pages/auth/LoginSSO")
);

const IndexPage = lazy(() =>
    import(/*webpackChunkName:'LoginPage'*/ "@/pages/auth/Index")
);
const NotFound = lazy(() =>
  import(/*webpackChunkName:'NotFoundPage'*/ "@/pages/NotFound")
);

export default function AuthRouter() {
  const location = useLocation();
  return (
    <Suspense fallback={<PageLoader />}>
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <PublicRoute
              path="/"
              component={LoginF} exact/>
          <PublicRoute
            path="/loginsso"
            component={LoginSSO} exact/>
          <PublicRoute component={Login} path="/login" exact />
          <PublicRoute component={LoginF} path="/auth" exact />
          <Route
            path="*"
            component={NotFound}
            render={() => <Redirect to="/notfound" />}
          />
        </Switch>
      </AnimatePresence>
    </Suspense>
  );
}
