import actionTypes from "@/config/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {


        case actionTypes.GET_COMPETENCE_ASSESSMENT_DATA:
            return {
                ...state,
                competenceAssessmentError: null,
                competenceAssessmentData: action.payload,
            };
        case actionTypes.GET_COMPETENCE_ASSESSMENT_ERROR:
            return {
                ...state,
                competenceAssessmentData: null,
                competenceAssessmentError: action.payload,
            };




        case actionTypes.GET_COMPETENCES_DATA:
            return {
                ...state,
                competenceError: null,
                competenceData: action.payload,
            };
        case actionTypes.GET_COMPETENCES_ERROR:
            return {
                ...state,
                competenceData: null,
                competenceError: action.payload,
            };


        case actionTypes.GET_COMPETENCESSCAPEBOOK_DATA:
            return {
                ...state,
                competenceScapeBookError: null,
                competenceScapeBookData: action.payload,
            };
        case actionTypes.GET_COMPETENCESSCAPEBOOK_ERROR:
            return {
                ...state,
                competenceScapeBookData: null,
                competenceScapeBookError: action.payload,
            };

        case actionTypes.GET_SCAPEBOOK_DATA:
            return {
                ...state,
                scapeBookError: null,
                scapeBookData: action.payload,
            };
        case actionTypes.GET_SCAPEBOOK_ERROR:
            return {
                ...state,
                scapeBookData: null,
                scapeBookError: action.payload,
            };


        case actionTypes.GET_CHALLENGE_DATA:
            return {
                ...state,
                challengeError: null,
                challengeData: action.payload,
            };
        case actionTypes.GET_CHALLENGE_ERROR:
            return {
                ...state,
                challengeData: null,
                challengeError: action.payload,
            };


        case actionTypes.GET_CHALLENGE_ASSESSMENT_DATA:
            return {
                ...state,
                challengeAssessmentError: null,
                challengeAssessmentData: action.payload,
            };
        case actionTypes.GET_CHALLENGE_ASSESSMENT_DATA:
            return {
                ...state,
                challengeAssessmentData: null,
                challengeAssessmentError: action.payload,
            };

        default:
            return state;
    }

}
