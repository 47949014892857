// export { default as AuthRouter } from "./AuthRouter";
// export { default as AppRouter } from "./AppRouter";
import React, { useEffect } from "react";
import '@/assets/sass/layout.scss'
import AuthRouter from "./AuthRouter";
import AppRouter from "./AppRouter";

import localOrSessionStore from "@/utils/localOrSessionStore";
import variables from "@/config/variables";
import { Layout } from "antd";
import TutorRouter from "@/router/TutorRouter";
export default function Router() {
  const storage = localOrSessionStore({ key: variables.REMEMBER });
  var token = (storage.getItem(variables.TOKEN));


  useEffect(() => {
  }, [token]);

  if (!token) {
    return (
        <Layout style={{minHeight: "100vh"}}>
          <AuthRouter/>
        </Layout>
    );
  }else {
    let mType = JSON.parse(storage.getItem(variables.USER)).type;
  
    if (mType === "TUTOR"){
        return (
            <div className={"defaultBack admin-layout"}>
                <TutorRouter />
                <div className="footer">© Proyecto The Interview</div>
            </div>

        );
    }else {
        return (
            <div className={"defaultBack admin-layout"}>
                <AppRouter/>
                <div className={`${ 
                  (window.location.href.includes("one") || window.location.href.includes("two")  || window.location.href.includes("three") 
                  || window.location.href.includes("four") || window.location.href.includes("shop") 
                    ) ? 'footertrs' : 'footer'}`}>© Proyecto The Interview</div>
            </div>

        );
    }
  }
}

// export default App;
