import actionTypes from "@/config/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_DEGREES_DATA:
            return {
                ...state,
                degreesError: null,
                degreesData: action.payload,
            };
        case actionTypes.GET_DEGREES_ERROR:
            return {
                ...state,
                degreesData: null,
                degreesError: action.payload,
            };


        default:
            return state;
    }

}
