import actionTypes from "@/config/actionTypes";


export const degreeActions = {
    getDegrees
};

function getDegrees() {
    return {
        type: actionTypes.GET_DEGREES,
    };
}
