import actionTypes from "@/config/actionTypes";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_QUESTIONNAIRE_DEGREE_DATA:
            return {
                ...state,
                questionnaireDegreeError: null,
                questionnaireDegreeData: action.payload,
            };
        case actionTypes.GET_QUESTIONNAIRE_DEGREE_ERROR:
            return {
                ...state,
                questionnaireDegreeData: null,
                questionnaireDegreeError: action.payload,
            };

        case actionTypes.GET_ASSESSMENTQUESTIONNAIRE_DEGREE_DATA:
            return {
                ...state,
                assessmentQuestionnaireDegreeError: null,
                assessmentQuestionnaireDegreeData: action.payload,
            };
        case actionTypes.GET_ASSESSMENTQUESTIONNAIRE_DEGREE_ERROR:
            return {
                ...state,
                assessmentQuestionnaireDegreeData: null,
                assessmentQuestionnaireDegreeError: action.payload,
            };

        case actionTypes.SAVE_useranswers_DATA:
            return {
                ...state,
                saveuseranswersError: null,
                saveuseranswersData: action.payload,
            };
        case actionTypes.SAVE_useranswers_ERROR:
            return {
                ...state,
                saveuseranswersData: null,
                saveuseranswersError: action.payload,
            };
        case actionTypes.CLEAR_useranswers:
            return {
                ...state,
                saveuseranswersData: null,
                saveuseranswersError: null,
            };

        case actionTypes.GET_USERREPORT_DATA:
            return {
                ...state,
                getUserReportError: null,
                getUserReportData: action.payload,
            };
        case actionTypes.GET_USERREPORT_ERROR:
            return {
                ...state,
                getUserReportData: null,
                getUserReportError: action.payload,
            };
        case actionTypes.GET_useranswers_DATA:
            return {
                ...state,
                getuseranswersError: null,
                getuseranswersData: action.payload,
            };
        case actionTypes.GET_useranswers_ERROR:
            return {
                ...state,
                getuseranswersData: null,
                getuseranswersError: action.payload,
            };
        case actionTypes.GET_useranswers_CLEAR:
            return {
                ...state,
                getuseranswersData: null,
                getuseranswersError: null,
            };
        default:
            return state;
    }

}
