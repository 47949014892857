const variables = {
    AUTH: "fe_tec_PortalAuth_v2",
    USER: "fe_tec_PortalUser_v2",
    COMPLET: "fe_tec_PortalCompleto_v2",
    EMAIL: "fe_tec_EmailAuth_v2",
    REMEMBER: "fe_tec_PortalRemember_v2",
    TOKEN: "fe_tec_PortalToken_v2",
    TOKENREFRESH: "fe_tec_PortalRefreshToken_v2",
  };


  export default variables;

