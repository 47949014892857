import history from "@/utils/history";
import {takeEvery, call, put, select} from 'redux-saga/effects'
import actionTypes from "@/config/actionTypes";
import {request} from "@/request";

export default function* watcherUserBadges() {
    yield takeEvery(actionTypes.GET_USERBADGES, getUserBadges)
    yield takeEvery(actionTypes.SAVE_USERBADGES, saveUserBadges)

}

console.log("SAVE_USERBADGES",actionTypes.SAVE_USERBADGES)
function* getUserBadges() {
    yield put({type: actionTypes.START_REQUEST})

    try {
        let apiEndpoint = `/user/badges`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_USERBADGES_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_USERBADGES_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_USERBADGES_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}


function* saveUserBadges({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    console.log("payload",payload)
    try {
        let apiEndpoint = `/user/badge`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.post(apiEndpoint, payload).then((response) => {
                        resolve(response)
                    })
                }),
        )
        console.log("res",res)
        if (res.success && res.result) {
            yield put({type: actionTypes.SAVE_USERBADGES_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.SAVE_USERBADGES_ERROR, payload: res})
        }
        yield put({type: actionTypes.SAVE_USERBADGES_CLEAR, payload: null})
    } catch (e) {
        yield put({type: actionTypes.SAVE_USERBADGES_ERROR, payload: e})
        yield put({type: actionTypes.SAVE_USERBADGES_CLEAR, payload: null})
    }
    yield put({type: actionTypes.END_REQUEST})
}

