import history from "@/utils/history";
import {takeEvery, call, put, select} from 'redux-saga/effects'
import actionTypes from "@/config/actionTypes";
import {request} from "@/request";

export default function* watcherChallenge() {
    yield takeEvery(actionTypes.GET_CHALLENGE, getChallenge)
    yield takeEvery(actionTypes.GET_SCAPEBOOK, getScapeBook)
    yield takeEvery(actionTypes.GET_CHALLENGE_ASSESSMENT, getChallengeAssessment)
    yield takeEvery(actionTypes.GET_COMPETENCE_ASSESSMENT, getCompetenceAssessment)
    yield takeEvery(actionTypes.GET_COMPETENCES, getCompetences)
    yield takeEvery(actionTypes.GET_COMPETENCESSCAPEBOOK, getCompetenceScapeBook)

}


function* getCompetences({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    try {
        let apiEndpoint = `/user/competences/${payload.code}`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_COMPETENCES_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_COMPETENCES_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_COMPETENCES_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}


function* getCompetenceScapeBook({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    try {
        let apiEndpoint = `/user/competence/scapebook/${payload.code}`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_COMPETENCESSCAPEBOOK_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_COMPETENCESSCAPEBOOK_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_COMPETENCESSCAPEBOOK_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}

function* getScapeBook({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    try {
        let apiEndpoint = `/user/scapebook/${payload.code}`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_SCAPEBOOK_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_SCAPEBOOK_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_SCAPEBOOK_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}
function* getChallenge({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    try {
        let apiEndpoint = `/user/challenge/${payload.code}`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_CHALLENGE_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_CHALLENGE_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_CHALLENGE_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}



function* getCompetenceAssessment({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    console.log("payload",payload)
    try {
        let apiEndpoint = `/user/competence/${payload.uuid}/assessments`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_COMPETENCE_ASSESSMENT_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_COMPETENCE_ASSESSMENT_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_COMPETENCE_ASSESSMENT_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}

function* getChallengeAssessment({payload}) {
    yield put({type: actionTypes.START_REQUEST})

    console.log("payload",payload)
    try {
        let apiEndpoint = `/user/challenge/${payload.uuid}/assessment`
        const res = yield call(
            () =>
                new Promise((resolve, reject) => {
                    request.get(apiEndpoint, null).then((response) => {
                        resolve(response)
                    })
                }),
        )

        if (res.success && res.result) {
            yield put({type: actionTypes.GET_CHALLENGE_ASSESSMENT_DATA, payload: res.result})
        } else {
            yield put({type: actionTypes.GET_CHALLENGE_ASSESSMENT_ERROR, payload: res})
        }
    } catch (e) {
        yield put({type: actionTypes.GET_CHALLENGE_ASSESSMENT_ERROR, payload: e})
    }
    yield put({type: actionTypes.END_REQUEST})
}

