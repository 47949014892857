
import actionTypes from "@/config/actionTypes";


export const challengeActions = {
    getChallenge,getChallengeAssessment,getScapeBook,getCompetences,getCompetenceScapeBook,getCompetenceAssessment
};



function getCompetenceAssessment(uuid) {
    return {
        type: actionTypes.GET_COMPETENCE_ASSESSMENT,
        payload: {uuid}
    };
}


function getCompetences(code) {
    return {
        type: actionTypes.GET_COMPETENCES,
        payload: {code}
    };
}



function getCompetenceScapeBook(code) {
    return {
        type: actionTypes.GET_COMPETENCESSCAPEBOOK,
        payload: {code}
    };
}

function getScapeBook(code) {
    return {
        type: actionTypes.GET_SCAPEBOOK,
        payload: {code}
    };
}

function getChallenge(code) {
    return {
        type: actionTypes.GET_CHALLENGE,
        payload: {code}
    };
}


function getChallengeAssessment(uuid) {
    return {
        type: actionTypes.GET_CHALLENGE_ASSESSMENT,
        payload: {uuid}
    };
}
