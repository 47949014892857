import { all } from "redux-saga/effects";
import watcherAuthentication from "./auth.saga";
import watcherSteps from './step.saga'
import watcherDegrees from './degree.saga'
import watcherQuestionnaires from './questionnaire.saga'
import watcherChallenge from './challenge.saga';
import watcherUserBadges from "@/sagas/badge.saga";
export default function* rootSaga() {
    yield all([
        watcherAuthentication(), watcherSteps(),watcherDegrees(),watcherQuestionnaires(),
        watcherChallenge(),
        watcherUserBadges()

    ]);
}
