import actionTypes from "@/config/actionTypes";


export const authActions = {
    login,
    loginToken,
    endTransaction,
    forgotPassword,
    resetPassword,
    logout,
    getUserToken,
};

function endTransaction() {
    return {
        type: actionTypes.END_TRANSACTION,
    };
}

function getUserToken(token) {
    return {
        type: actionTypes.GET_USER,
        token: token,
    };
}

function loginToken(token) {
    const payload = { token };
    return {
        type: actionTypes.LOGIN_USERSSO,
        payload,
    };
}
function login(email, password, remember) {
    const payload = { email, password, remember };
    return {
        type: actionTypes.LOGIN_USER,
        payload,
    };
}
function forgotPassword(email) {
    const payload = { email };
    return {
        type: actionTypes.FORGOTPASSWORD,
        payload,
    };
}
function resetPassword(email, password, token) {
    const payload = { email, password };

    return {
        type: actionTypes.RESETPASSWORD,
        payload,
        token,
    };
}
function logout() {
    return {
        type: actionTypes.LOGOUT,
        payload: "",
    };
}

